import React from "react"
import PropTypes from "prop-types"
import { GlobalStyle } from "./globalStyle"
import { MainWrapper } from "./layoutComponents"
import Footer from "./footer"

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <GlobalStyle />
      <MainWrapper>
        <main id="main">{children}</main>
        <Footer />
      </MainWrapper>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
