import React from "react";
import { Container, ContentContainer } from "./layoutComponents";

const Footer = () => (
  <footer>
    <Container>
      <ContentContainer padding="2rem 0">
        <p style={{ fontSize: '.75rem' }}>
          © 2020, Built by 
          <a 
            href="https://rklassiter.netlify.app" 
            rel="noopener noreferrer"
            target="_blank"
            style={{ color: '#3ea5ce', fontWeight: '700' }}
          > Ryan</a> using
          <a 
            href="https://gatsbyjs.com" 
            rel="noopener noreferrer"
            target="_blank"
            style={{ color: '#3ea5ce', fontWeight: '700' }}
          > GatsbyJS</a>
        </p>
      </ContentContainer>
    </Container>
  </footer>
)

export default Footer;
