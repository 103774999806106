import styled from "styled-components"

export const MainWrapper = styled.div`
  margin: 0;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

export const Container = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 1150px;
  padding: 0 1.75rem;
  padding-top: 0;
`

export const ContentContainer = styled.section`
  height: 100%;
  margin: ${props => props.margin ? props.margin : '0 auto'};
  padding: ${props => props.padding && props.padding};
  max-width: 38rem;
  text-align: ${props => props.textCenter && props.textCenter};
`

export const Row = styled.div`
  display: flex;
  & > * {
    flex: ${props => (props.flex ? props.flex : "0 0 1")};
  };
  flex-direction: row;
  flex-wrap: ${props => (props.wrap ? props.wrap : "initial")};
  align-content: ${props =>
    props.alignContent ? props.alignContent : "initial"};
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : "initial"};
  @media screen and (max-width: 599px) {
    flex-direction: ${props => (props.mobile ? "column" : "row")};
  }
`

export const StyledFlex = styled.div`
  display: flex; 
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  margin: 2rem 0;

  > * {
      flex: 0 0 45%;
  }

  @media (max-width: 999px) {
      > * {
          flex: 0 0 100%;
      }
      
      #lastChild {
          margin-top: 2rem;
      }
  }
`